@keyframes right {
  from {
    background-color: green;
  }

  to {
    background-color: transparent;
  }
}

@keyframes left {
  from {
    background-color: red;
  }

  to {
    background-color: transparent;
  }
}

.tinder-container {
  overflow: hidden;
  .swipe {
    position: absolute;
  }

  .undo-button {
    background-color: #f1c40f;
    border-radius: 59%;
    width: 70px;
    height: 70px;
    border: 0px;
    color: black;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    outline: unset !important;
  }

  .restart-button {
    background-color: #2980b9;
    border-radius: 59%;
    width: 70px;
    height: 70px;
    border: 0px;
    color: black;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    outline: unset !important;
  }

  .left-button {
    background-color: #e74c3c;
    border-radius: 59%;
    width: 70px;
    height: 70px;
    border: 0px;
    color: black;
    font-size: 1.5rem;
    outline: unset !important;
    color: white;
    margin-right: 4rem;
  }

  .right-button {
    margin-left: 4rem;

    background-color: #2ecc71;
    border-radius: 59%;
    width: 70px;
    height: 70px;
    border: 0px;
    color: black;
    font-size: 1.5rem;
    outline: unset !important;
    color: white;
  }

  .cardContainer {
    width: 90vw;
    max-width: 260px;
    height: 300px;
  }

  .tinder-card {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid black;
    border-bottom: 0px;
  }

  .tinder-footer {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    background-color: white;
  }

  .cardContent {
    width: 100%;
    height: 100%;
  }

  .swipe:last-of-type {
  }

  .tinder-card h3 {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    color: black;
    text-shadow: 0px 0px 5px #ffffff;
  }
  .configuration {
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .tinder-card-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .right {
    animation-duration: 0.5s;
    animation-name: right;
  }

  .left {
    animation-duration: 0.5s;
    animation-name: left;
  }
}

@media (max-width: 980px) {
  .tinder-container {
    .tinder-card-container {
      .tinder-card {
        max-width: 600px !important;
      }
    }
  }
}
