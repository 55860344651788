.mobi-tag-li  {
  border-bottom: 1px solid lightgrey;
  &:hover {
    background-color: #f5f5f5;
  }
}

.tag-manager-button {
  width: 40px;
  border: none;
  background-color: transparent;
  outline: none !important;
  &:hover {
    color: #007bff;
  }
}
