.filter-row {
  flex-wrap: wrap;
  .form-select {
    max-width: 189px;
    width: 100%;
  }

  .tag-manager-button {
    border: 1px solid rgb(225, 229, 235);
    border-left: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .select-search__input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  select.custom-select {
    font-size: 0.8125rem !important;
    height: auto !important;
    line-height: 1.5 !important;
    padding: 0.4375rem 0.75rem !important;
  }
}
