@import "utilities/general";
@import "utilities/background";
@import "utilities/text";
@import "utilities/borders";

.hidden {
  display: none !important;
}

.modal-backdrop.fade {
  transition: none !important;
}
