.rcw-conversation-container > .rcw-header {
  background-color: #007bff;
}

.rcw-conversation-container {
  .rcw-title {
    color: white;
  }
}
.rcw-launcher {
  background-color: #007bff;
}

@media screen and (max-width: 800px) {
  .rcw-widget-container {
    height: 100% !important;
  }
}

.sc-launcher {
  cursor: pointer;
}

.rce-mbox-right {
  background-color: #a3eaf7;
}

.rce-mbox-right-notch {
  fill: #a3eaf7;
}

.sc-chat-window.opened {
  z-index: 10001;
}

.rce-mbox-text p {
  margin: 0px !important;
}

.rce-mbox-file > button {
  max-width: 280px !important;
}

.rce-mbox-photo {
  .rce-mbox-text {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sc-user-input--text {
  cursor: text !important;
}

.read-only {
  .sc-user-input {
    display: none;
  }
}
