// Bootstrap TagsInput Adjustments

.bootstrap-tagsinput {
  padding: $input-padding-y $input-padding-x / 2;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  border: $bootstrap-tagsinput-border;
  border-radius: $input-border-radius;

  @include transition($input-transition);

  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  > input {
    border: none;
    padding: 0;

    &:focus {
      outline: 0;
    }
  }

  .tag {
    display: inline-block;
    background: theme-color("light");
    padding: 0.125rem 1.375rem 0.125rem 0.625rem;
    margin-bottom: 0.3125rem;
    border-radius: 1.25rem;
    font-size: 0.6875rem;
    position: relative;
    text-transform: uppercase;
    border: 1px solid $border-color;
    color: $reagent-gray;

    > span {
      position: absolute;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
      right: 0.5rem;
      top: 50%;
      width: 0.625rem;
      height: 0.625rem;
      background-repeat: no-repeat;
      background-position: center center;
      transform: translateY(-50%);
      background-size: 100%;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
