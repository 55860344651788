// Text utilities adjustments

// Create text color variants for the new colors
@each $color, $value in $new-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

// Create text color variants for the new grays
@each $color, $value in $new-grays {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

// Create text color variants for the accent color
@include text-emphasis-variant(".text-accent", $accent-color);

// Light text adjustments
.text-light {
  color: $text-light-color !important;
}

// Semibold text
.text-semibold {
  font-weight: $text-semibold-font-weight;
}

@for $i from 0 through 1000 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }

  .min-h-#{$i} {
    min-height: #{$i}px !important;
  }

  .max-h-#{$i} {
    max-height: #{$i}px !important;
  }

  .min-w-#{$i} {
    min-width: #{$i}px !important;
  }

  .max-w-#{$i} {
    max-width: #{$i}px !important;
  }
}
