// General utilities

// Overflow helpers
.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-visible {
    overflow: visible;
  }
  &-scroll {
    overflow: scroll;
  }
  &-auto {
    overflow: auto;
  }
}

// Remove shadows
.no-shadow {
  box-shadow: none !important;
}

.clickable:hover {
  cursor: pointer;
}

.enlarge-on-hover:hover {
  transform: scale(1.2);
}

.text-hover-highlight:hover {
  color: var(--newbackground-color);
}

.click-blocked:hover {
  cursor: not-allowed;
}

:root {
  --newbackground-color: #007bff;
}

.context-highlight {
  box-shadow: #047bfe 0px 1px 9px 4px;
}

.MuiAutocomplete-popper {
  z-index: 11000 !important;
}

.wrap-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.hover-click {
  &:hover {
    cursor: pointer;
  }
}

.add-hover:hover {
  color: green;
  border-color: green !important;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.select-search {
  width: 100% !important;
  .select-search__input {
    padding-right: 30px;
  }
}

.owner-filter {
  width: 100%;
  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }
}

.form-select {
  height: auto !important;
  padding: 0.4375rem 0.75rem;
  line-height: 1.5;
  font-size: 0.8125rem;
}

.small-title {
  font-size: 1rem;
}

.medium-title {
  font-size: 1.3rem;
}

//Button
.white-button {
  background-color: white;
  border: none;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.right-sidebar {
  top: 3.9rem;
  height: calc(100vh - 4.1rem);
  position: fixed;
  width: 37rem;
  right: 0;
  max-width: 37rem;
  background-color: white;
  right: -37rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;
  // overflow-y: auto !important;
  transition: all 0.5s ease;
}

.project-info-right-sidebar {
  top: 0rem !important;
  z-index: 10001 !important;
  height: 100vh !important;
}
.sidebar-right-mobiList {
  height: 100vh !important;
  top: 0rem !important;
  z-index: 1000 !important;
  width: 30rem !important;
}
.right-sidebar-transition {
  transition: all 0.5s ease;
  right: 0;
}

.left-sidebar {
  height: calc(100vh - 4.1rem);
  position: fixed;
  width: 37rem;
  left: 4.8rem;
  max-width: 37rem;
  background-color: white;
  left: -37rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;
  transition: all 0.5s ease;
}

.left-sidebar-transition {
  transition: all 0.5s ease;
  left: 4.8rem;
}

.checklist-float-button {
  width: 3.75rem;
  height: 3.75rem;
  background-color: #00a2bf !important;
  bottom: 1.2rem;
  position: absolute;
  right: 6.6rem;
  color: white !important;
  margin: 0rem;
  z-index: 10000;
  border-radius: 50%;
  padding: 0.58rem;
}

.project-checklist-float-button {
  position: fixed !important;
  bottom: 25px !important;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.hidden {
  display: none !important;
}

.Toastify__toast-container {
  z-index: 11000;
}

.sc-launcher {
  z-index: 10001;
}

.ReactModal__Overlay {
  z-index: 10100 !important;
}

.divLoader {
  width: 40px;
  height: 40px;
  border: 7px solid rgba(76, 175, 80, 0.71);
  border-left-color: #c9d6c9;
  border-radius: 100%;
  animation: divLoader 1s linear infinite;
}

@keyframes divLoader {
  to {
    transform: rotate(360deg);
  }
}

.ril__caption {
  justify-content: center;
  align-items: center;
}

.ril__captionContent {
  max-height: 150px;
  width: 100%;
  padding: 20px !important;
  word-break: break-all;
  overflow: auto;
  position: relative;
}
.sticky-top {
  z-index: 999 !important;
}

button:focus {
  outline: none !important;
}

.sortTableArea {
  .rst__tree {
    min-height: 300px !important;
  }
}

.custom-control {
  word-break: break-all;
}

.card-produt-img {
  box-shadow: none !important;
}

button.transparent {
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  padding-left: 0px;
  padding-right: 0px;
  color: #5a6169;
}

div.label {
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.rt-td {
  white-space: break-spaces !important;
}
