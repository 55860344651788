.tui-image-editor-controls > div {
  display: none !important;
}

.tui-image-editor-menu {
  display: none !important;
}

.tui-image-editor-submenu {
  display: none !important;
}

.tui-image-editor-header {
  display: none !important;
}

.image-editor {
  .select-search__option {
    font-size: 0.5rem;
  }
}
